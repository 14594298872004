import { observer } from "mobx-react";
import React from "react";
import { useApp } from "../../App";
import CabinetLayoutStyle from "../cabinet/CabinetLayout.module.scss";
import BuyPackView from "./BuyPackView";

const BuyPackCollectionView = observer(() => {
	const app = useApp();

	const drops = app.store.drops.queryAll();
	const packViews = drops.map(drop => {
		const packData = app.assetConf.packs.find(it => it.template_id === drop.templateId);
		if (!packData)
			return null;
		return <BuyPackView drop={drop}
		                    packData={packData}
		                    key={`buy-puck-${packData.template_id}`}/>;
	});

	return (
		<div className={CabinetLayoutStyle.main_container}>
			{packViews}
		</div>
	);
});

export default BuyPackCollectionView;
