import { IPackJson } from "@pro/common/conf";
import { EosAsset } from "@pro/common/eos";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { useApp } from "../../App";
import { BuyPackAction } from "../../eos/actions/BuyPackAction";
import { cutLinkFromPackDesc } from "../../helpers/PackHelper";
import { L } from "../../l10n/L10n";
import { DropModel } from "../../stores/DropModel";
import button from "../btn.module.css";
import Button from "../Button";
import Styles from "../cabinet/PacksView.module.scss";
import LoginBtn from "../LoginBtn";
import BuyStyles from "./BuyPackView.module.scss";

function formatPrice(price: EosAsset): string
{
	const fraction = 10 ** price.symbol.precision;
	const amount = (price.amount / fraction).toFixed(0);
	return `${amount} ${price.symbol.code}`;
}


const BuyPackView = observer((
	{
		drop,
		packData,
	}: {
		drop: DropModel,
		packData: IPackJson,
	}) =>
{
	const app = useApp();

	const inputRef = useRef<HTMLInputElement>(null);
	const [amount, setAmount] = useState(1);
	const [btnReady, setBtnReady] = useState(true);

	useEffect(() => {
		if (!inputRef.current)
			return;

		if (!amount || amount < 0)
			setAmount(0);

		if (amount > drop.availableToClaim)
			setAmount(drop.availableToClaim);

		inputRef.current.value = String(amount);
	}, [amount]);

	const onBuyClick = () => {
		const price = new EosAsset(drop.price.amount * amount, drop.price.symbol);
		const referrer = app.localData.referrer || app.chainConf.TB_ACCOUNT;
		new BuyPackAction(price, amount, drop.dropId, referrer)
			.setReadyStateAction(setBtnReady)
			.onSuccess(() => app.showInfo(L.buyPackPage.successMessage))
			.executeAsync();
	};

	const btn = app.store.logined
		? <Button
			text="Buy"
			onClick={() => onBuyClick()}
			disabled={!amount || amount < 1}
			ready={btnReady}
		/>
		: <LoginBtn/>;

	return (
		<div className={Styles.content}>
			<img className={Styles.packImg} src={packData.img} alt="pack image"/>
			<div className={Styles.desc_container}>
				<div className={Styles.desc_content}>
					<div className={BuyStyles.title}>
						<h2>{packData.name}</h2>
						<div>
							{drop.currentClaimed}/
							<b>{drop.maxClaimable}</b>
						</div>
					</div>
					<p>{cutLinkFromPackDesc(packData.description)}</p>
					<div className={Styles.probability_content}>
						<div>
							<div className={Styles.probability_info}>
								<span>{L.rarity.common}:</span>
								<strong>{packData.rarity_percent[0]}%</strong>
							</div>
							<div className={Styles.probability_info}>
								<span>{L.rarity.rare}:</span>
								<strong>{packData.rarity_percent[1]}%</strong>
							</div>
						</div>
						<div>
							<div className={Styles.probability_info}>
								<span>{L.rarity.unique}:</span>
								<strong>{packData.rarity_percent[2]}%</strong>
							</div>
							<div className={Styles.probability_info}>
								<span>{L.rarity.epic}:</span>
								<strong>{packData.rarity_percent[3]}%</strong>
							</div>
						</div>
						<div>
							<div className={Styles.probability_info}>
								<span>{L.rarity.legendary}:</span>
								<strong>{packData.rarity_percent[4]}%</strong>
							</div>
							<div className={Styles.probability_info}>
								<span>{L.rarity.mythic}:</span>
								<strong>{packData.rarity_percent[5]}%</strong>
							</div>
						</div>
					</div>
				</div>
				<div className={BuyStyles.btn_container}>
					<div className={BuyStyles.price}>
						{formatPrice(drop.price)}
					</div>
					<div className={BuyStyles.input_box}>
						<button className={BuyStyles.minus} onClick={() => setAmount(amount - 1)}>-</button>
						<input
							id={`buy-pack-${packData.id}`}
							className={BuyStyles.input}
							ref={inputRef}
							type={"number"}
							name={"amount"}
							defaultValue={amount}
							min={0}
							max={drop.availableToClaim}
							onChange={e => setAmount(parseInt(e.target.value))}
						/>
						<button className={BuyStyles.plus} onClick={() => setAmount(amount + 1)}>+</button>
					</div>
					{btn}
				</div>
			</div>
		</div>

	);
});

export default BuyPackView;

