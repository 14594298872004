import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useApp } from "../App";
import BuyPackCollectionView from "../components/buy-pack/BuyPackCollectionView";
import CabinetLayout from "../components/cabinet/CabinetLayout";
import CabinetTopView from "../components/cabinet/CabinetTopView";
import SEO from "../components/seo";
import { L } from "../l10n/L10n";

const BuyPackPage = observer(() => {
	const app = useApp();

	useEffect(() => {
		app.worldService.updateDrops().catch();
	}, []);

	useEffect(() => {
		if (app.store.logined)
			app.worldService.updateAssets().catch();
	}, [app.store.logined]);

	return (
		<CabinetLayout>
			<SEO title={L.buyPackPage.title}
			     description="Tribal Books is a free-to-play online digital collectible nft-card game"/>

			<CabinetTopView title={L.buyPackPage.title}
			                desc={L.buyPackPage.desc}
			/>
			<BuyPackCollectionView/>
		</CabinetLayout>
	);
});

export default BuyPackPage;