import { TUint64 } from "@pro/common/contracts/atomicassets";
import { EosAsset } from "@pro/common/eos";
import { eos, world } from "../../App";
import { EosActionBase } from "../EosActionBase";

export class BuyPackAction extends EosActionBase
{
	constructor(private readonly transferQuantity: EosAsset,
	            private readonly claimAmount: number,
	            private readonly dropId: TUint64,
	            private readonly referrer: string)
	{
		super("buy_pack");
	}

	async onExecute()
	{
		await eos.buyPack(this.transferQuantity, this.claimAmount, this.dropId, this.referrer);
	}

	async onSelfUpdate()
	{
		await world.updateAssets();
		await world.updateDrops();
		await world.mustUpdateBalance();
	}
}