import classNames from "classnames";
import React, { MouseEventHandler } from "react";
import Styles from "./Button.module.scss";

export type ButtonPropsType = {
	text: string,
	onClick?: MouseEventHandler
	style?: ButtonStyle,
	disabled?: boolean,
	ready?: boolean
}

export enum ButtonStyle
{
	STANDARD = 0,
}

function getStyleClass(style: ButtonStyle)
{
	switch (style) {
		case ButtonStyle.STANDARD:
			return Styles.standard;
	}
}

const Button = (
	{
		text,
		onClick = () => {},
		style = ButtonStyle.STANDARD,
		disabled = false,
		ready = true,
	}: ButtonPropsType) => {

	const className = classNames(
		Styles.button,
		getStyleClass(style),
		{[Styles.disabled]: disabled},
		{[Styles.busy]: !ready},
	);

	const handleClick: MouseEventHandler = (e) => {
		if (!disabled && ready)
			onClick(e);
	};

	return (
		<button
			className={className}
			onClick={handleClick}
			disabled={disabled}
		>
			{text}
		</button>
	);
};

export default Button;
